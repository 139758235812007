<template>
<div>
    <multiselect :disabled="disableValue" v-if="isMultiple" v-model="DisplayValue" :options="options"  @input="UpdateData" :multiple="true" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" placeholder="Select Vendor" v-bind:class="$i18n.locale == 'en' ? 'text-left ' : 'arabicLanguage '">
        <span slot="noResult" class="btn btn-primary " v-on:click="AddCustomer()" v-if="isValid('CanAddCustomer')">Create Vandor</span><br />
    </multiselect>
    <multiselect :disabled="disableValue" v-else v-model="DisplayValue" :options="options"  @input="UpdateData" :multiple="false" track-by="name" ref="multiselect" :searchable="true" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" placeholder="Select Vendor" v-bind:class="$i18n.locale == 'en' ? 'text-left ' : 'arabicLanguage '">
        <span slot="noResult" class="btn btn-primary " v-on:click="AddCustomer()" v-if="isValid('CanAddCustomer')">Create Vandor</span><br />
    </multiselect>

    <modal :show="show" v-if="show">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel">Add Vandor</h6>
               
               
                <button type="button" class="btn-close" v-on:click="close()"></button>
            </div>
            <div class="modal-body">

                <div class="row ">
                    <div  class="col-sm-12">
                        <label>Name :<span class="text-danger"> *</span></label>
                        <div v-bind:class="{'has-danger' : $v.customer.englishName.$error}">
                            <input class="form-control "  v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="$v.customer.englishName.$model" />
                            <span v-if="$v.customer.englishName.$error" class="error text-danger">
                                <span v-if="!$v.customer.englishName.required">{{ $t('CustomerDropdown.EngValidation') }} </span>
                                <span v-if="!$v.customer.englishName.maxLength">{{ $t('CustomerDropdown.EngMax') }} </span>
                            </span>
                        </div>
                    </div>
                   
                   
                    <div class="col-sm-12" v-if="!customer.isCashCustomer">
                        <label>{{ $t('CustomerDropdown.RegistrationDate') }} :</label>
                        <div v-bind:class="{'has-danger' : $v.customer.registrationDate.$error}">
                            <datepicker v-model="$v.customer.registrationDate.$model"></datepicker>
                            <span v-if="$v.customer.registrationDate.$error" class="error text-danger">
                            </span>
                        </div>
                    </div>
                  
                  
                    
                    <div class="col-sm-12">
                        <label v-if="!customer.isCashCustomer">{{ $t('CustomerDropdown.VAT/NTN/Tax No') }} :<span class="text-danger"> *</span></label>
                        <label v-else>Customer Id :</label>
                        <div >
                            <input class="form-control "  v-model="customer.vatNo" />
                           
                        </div>
                    </div>
                   

                    <div class="col-sm-12">
                        <label>Mobile :</label>
                        <input class="form-control text-left" v-model="customer.contactNo1" />
                    </div>

                    <div class="col-sm-12">
                        <label>Address :</label>
                        <div>
                            <textarea class="form-control text-left" v-model="customer.address" />

                            </div>
                        </div>
                      

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="SaveCustomer" v-bind:disabled="$v.customer.$invalid"> {{ $t('CustomerDropdown.btnSave') }}</button>
                    <button type="button" class="btn btn-soft-secondary btn-sm " v-on:click="close()">{{ $t('CustomerDropdown.btnClear') }}</button>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import moment from 'moment';
import Multiselect from 'vue-multiselect'
import {
    required,
    maxLength,
} from "vuelidate/lib/validators"
export default {
    name: 'CustomerDropdown',
    props: ["values", "disable", "paymentTerm", "isMultiple", "isCredit", "formName",'customerList'],
    mixins: [clickMixin],
    components: {
        Multiselect,

    },
    data: function () {
        return {
            b2b: false,
            salutatioRender: 0,
            b2c: false,
            isCashCustomer: false,
            arabic: '',
            english: '',
            contactCode: '',
            cashCustomerCode: '',
            selectedValue: [],
            options: [],
            optionsb2b: [],
            value: '',
            disableValue: false,
            show: false,
            customer: {
                id: '00000000-0000-0000-0000-000000000000',
                customerType: '',
                category: '',
                code: '',
                registrationDate: '',
                englishName: '',
                arabicName: '',
                vatNo: '',
                customerCode: '',
                customerDisplayName: '',
                contactNo1: '',
                address: '',
                paymentTerms: '',
                isCustomer: true,
                isVendor: true,
                isCashCustomer: false,
                isActive: true
            },
        }
    },
    validations: {
        customer: {
            code: {},
           
            registrationDate: {
            },
            englishName: {
                required,
                maxLength: maxLength(30)
            },
          
          
        }
    },
    methods: {
        EmptyRecord: function () {

            this.DisplayValue = '';

        },
        Remove: function () {
            this.value='';

        },

        isCashCustomerFunc: function () {

            if (this.customer.isCashCustomer) {

                this.customer.customerType = 'Individual';
                this.customer.paymentTerms = 'Cash';
                this.customer.vatNo = '';
                this.customer.registrationDate = moment().format('LL');

            } else {
                this.customer.vatNo = '';
                this.customer.customerType = '';
            }
        },
        asyncFind: function (search) {

            this.getData(true, search);
        },
        GetAutoCodeGenerator: function () {
            var root = this;
            var token = '';
            if (root.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https
                .get('/Contact/AutoGenerateCode?isCustomer=true' + '&isCashCustomer=' + this.isCashCustomer, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }).then(function (response) {
                    if (response.data != null) {
                        root.contactCode = response.data.contact;
                        root.cashCustomerCode = response.data.cashCustomer;

                    }
                });
        },
        DisplayName: function () {
            this.salutatioRender++;
        },
        AddCustomer: function () {

            var name = this.$refs.multiselect.search;

            this.isCashCustomer = localStorage.getItem('CashCustomer') == 'true' ? true : false;

            this.$v.$reset();

            {
                this.customer = {
                    id: '00000000-0000-0000-0000-000000000000',
                    code: '',
                    customerType: '',
                    paymentTerms: '',
                    category: '',
                    registrationDate: '',
                    englishName: name,
                    arabicName: '',
                    vatNo: '',
                    customerCode: '',
                    contactNo1: '',
                    address: '',
                    customerDisplayName: '',
                    isCustomer: true,
                    isVendor: true,
                    isActive: true,
                    isCashCustomer: false,
                }
            }

            if (this.isCashCustomer) {
                this.customer.isCashCustomer = true;
                this.isCashCustomerFunc();

            }
            if (this.isCredit == true) {
                this.customer.isCashCustomer = false;
            }
            this.b2b = localStorage.getItem('b2b') == 'true' ? true : false;
            this.b2c = localStorage.getItem('b2c') == 'true' ? true : false;
            if (this.b2b && !this.b2c) {
                this.customer.category = 'B2B – Business to Business';
            }
            if (!this.b2c && this.b2c) {
                this.customer.category = 'B2C – Business to Client';
            } else {
                this.customer.category = 'B2C – Business to Client';
            }

            this.GetAutoCodeGenerator();
            this.customer.registrationDate = moment().format('llll');
            this.show = !this.show;
        },
        SaveCustomer: function () {
            this.loading = true;
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            if (this.customer.isCashCustomer) {
                this.customer.code = this.cashCustomerCode;

            } else {
                this.customer.code = this.contactCode;
            }

            root.$https
                .post('/Contact/SaveContact', this.customer, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then(response => {
                    if (response.data.message.id != '00000000-0000-0000-0000-000000000000' && response.data.action == "Add") {
                        root.loading = false
                        root.info = response.data.bpi
                        root.$swal({
                            title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved Successfully' : 'حفظ بنجاح',
                            text: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Saved' : 'تم الحفظ',
                            type: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonStyling: false,
                            icon: 'success',
                            timer: 1500,
                            timerProgressBar: true
                        });
                        root.show = !root.show;
                        root.getData(true);
                        root.$emit('input', response.data.message.id);

                    }
                })
                .catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        title: (root.$i18n.locale == 'en' || root.isLeftToRight()) ? 'Error!' : 'خطأ',
                        text: error.response.data,
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                    root.show = !root.show;
                    root.loading = false
                })
                .finally(() => root.loading = false)
        },
        close: function () {
            this.show = false;
        },
        GetCustomerAddress: function () {

            if (this.value != null && this.value != '') {
                if (this.value.length > 0)
                    return this.value[0];
                else
                    return this.value;
            } else {
                return {
                    address: '',
                    mobile: '',
                    email: '',

                }
            }

        },
        UpdateData: function () {
                var selectedvalue = this.value.id;
                if (!this.emptyselect) {
                    this.selectedValue = [];
                }
                this.$emit('input', selectedvalue, this.options.find(x => x.id == selectedvalue));
                
            },
        getData: function (quick, search) {
            

            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            if (quick) {
                root.options = [];
            }
            if (search == undefined || search == null) {
                search = '';
            }
            var paymentTerms = '';
            var multipleAddress = localStorage.getItem('MultipleAddress') == 'true' ? true : false;

            paymentTerms = this.paymentTerm == 'Credit' || this.paymentTerm == 'آجـل' ? 'Credit' : '';

            this.$https.get('/Contact/ContactList?IsDropDown=' + true + '&customerType=' + 'Vendor' + '&isCustomer=' + true + '&isActive=' + true + '&paymentTerms=' + paymentTerms + '&searchTerm=' + search + '&isCashCustomer=' + this.isCashCustomer + '&multipleAddress=' + multipleAddress, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            }).then(function (response) {
                if (response.data != null) {
                    response.data.results.forEach(function (cust) {
                        if (cust.contactNo1 == null)
                            cust.contactNo1 = '';
                        if (cust.customerCode == null)
                            cust.customerCode = '';

                        var displayName = '';
                        if (cust.customerDisplayName != null && cust.customerDisplayName != "") {
                            displayName = '\u202A' + cust.customerDisplayName + '\u202C' + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + cust.contactNo1+cust.customerCode;
                        }
                        else if (cust.englishName != '' && cust.englishName != null) {
                            displayName = cust.englishName + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + cust.contactNo1+cust.customerCode;
                        }
                        else if (cust.arabicName != '' && cust.arabicName != null) {
                            displayName = '\u202A' + cust.arabicName + '\u202C' + '\xa0\xa0\xa0\xa0\xa0\xa0\xa0' + cust.contactNo1+cust.customerCode;
                        }
                        else {
                            displayName = cust.englishName+cust.customerCode;
                        }

                        var addressList = [];
                        if (cust.deliveryAddresses != null) {
                            addressList = cust.deliveryAddresses.filter(x => x.isActive)
                        }
                        root.options.push({
                            id: cust.id,
                            deliveryAddressList: addressList,
                            code: cust.code,
                            customerDisplayName: cust.customerDisplayName,
                            isCashCustomer: cust.isCashCustomer,
                            englishName: cust.englishName,
                            arabicName: cust.arabicName,
                            commercialRegistrationNo: cust.commercialRegistrationNo,
                            vatNo: cust.vatNo,
                            contactNo1: cust.contactNo1,
                            email: cust.email,
                            address: cust.address,
                            shippingAddress: cust.shippingAddress,
                            regionId: cust.regionId,
                            creditDays: cust.creditDays,
                            creditLimit: cust.creditLimit,
                            creditPeriod: cust.creditPeriod,
                            advanceAccountId: cust.advanceAccountId,
                            deliveryAddress: cust.deliveryAddress,
                            shippingOther: cust.shippingOther,
                            deliveryOther: cust.deliveryOther,
                            paymentTerms: cust.paymentTerms,
                            customerCode: cust.customerCode,
                            name: displayName,
                            priceLabelId:cust.priceLabelId,
                            companyNameEnglish: cust.companyNameEnglish,
                            companyNameArabic: cust.companyNameArabic,
                            prefix: cust.prefix,
                        });

                    });
                    
                    if (root.formName == 'ServiceQuotation' || root.formName == 'ServiceSaleOrder' || root.formName == 'ServiceProformaInvoice') {
                        const index = root.options.findIndex(x => x.englishName === 'Walk-In');
                        if (index !== -1) {
                            //   const newObj = { ...root.options[index]};
                            root.options.splice(index, 1);
                        }

                    }
                    else if (root.formName == 'SaleInvoice') {
                        if (root.isCashCustomer) {
                            if (localStorage.getItem('IsCashCustomer') == 'true') {
                                if (root.isCredit == false) {
                                    if (root.values == null || root.values == undefined || root.values == '') {
                                        var walkInCustomer = root.options.find(function (x) {
                                            return x.englishName == 'Walk-In';
                                        })
                                        if (walkInCustomer != undefined && walkInCustomer != null) {

                                            root.$emit('input', walkInCustomer.id, walkInCustomer.advanceAccountId, walkInCustomer);
                                        }

                                    }

                                }

                            }
                        } 
                    }
                    else {
                            const index = root.options.findIndex(x => x.englishName === 'Walk-In');
                            if (index !== -1) {
                                //   const newObj = { ...root.options[index]};
                                root.options.splice(index, 1);
                            }

                        }
                }
            }).then(function () {

                root.value = root.options.find(function (x) {
                    return x.id == root.values;
                })
                if (root.value == null || root.value == undefined || root.value == '') {
                    console.log();
                } else {
                    root.$emit('input', root.value.id, root.value.advanceAccountId, root.value);
                }
            });
        },
    },
    computed: {
        DisplayValue: {

            get: function () {
                if (this.value != "" || this.value != undefined) {
                    return this.value;
                }
                return this.values;
            },
            set: function (value) {

                if (value == null || value == undefined) {
                    this.value = value;
                    this.$emit('input', value, value);
                }
                else if (this.isMultiple == true) {
                    this.$emit('input', value, value);

                }
                else {

                    this.value = value;
                    this.$emit('input', value.id, value.advanceAccountId, value);
                }
            }
        }
    },
    mounted: function () {
        this.isCashCustomer = localStorage.getItem('CashCustomer') == 'true' ? true : false;

        this.english = 'true';
        this.arabic = localStorage.getItem('Arabic');

        this.getData();
        this.disableValue = this.disable;
    },
}
</script>
